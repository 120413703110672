<template>
    <app-layout class="account">
        <card class="account__tfa">
            <template #icon>
                <clip-icon />
            </template>

            <template #title>{{ $t('account.tfa.title') }}</template>

            <div class="account__text">
                <p>{{ $t('account.tfa.description.first') }}</p>

                <p>{{ $t('account.tfa.description.second') }}</p>

                <p>
                    {{ $t('account.tfa.status.title') }}:&nbsp;
                    <span>{{ $t('account.tfa.status.enabled') }}</span>
                </p>
            </div>

            <validation-observer ref="tfaObserver" v-slot="{ handleSubmit }" slim>
                <form @submit.prevent="handleSubmit(getRecoveryCodes)">
                    <validation-provider name="password_confirmation" rules="required" v-slot="{ errors, reset }" slim>
                        <input-group :errors="errors" required>
                            <input-component
                                v-model="tfa.password"
                                autocomplete="current-password"
                                type="password"
                                :placeholder="$t('account.tfa.form.password')"
                                @input="reset"
                                @mousedown.stop
                            />
                        </input-group>
                    </validation-provider>

                    <input :value="user.email" autocomplete="username" hidden />

                    <text-button full :disabled="tfa.loading" :loading="tfa.loading">
                        {{ $t('account.tfa.form.button') }}
                        <template #icon>
                            <retry-arrow-icon />
                        </template>
                    </text-button>
                </form>
            </validation-observer>
        </card>

        <card class="account__password">
            <template #icon>
                <key-icon />
            </template>

            <template #title>{{ $t('account.password.title') }}</template>

            <validation-observer ref="passwordObserver" v-slot="{ handleSubmit }" slim>
                <form @submit.prevent="handleSubmit(sendPasswordChange)">
                    <div>
                        <validation-provider name="password" rules="required|min:8" v-slot="{ errors, reset }" slim>
                            <input-group :errors="errors" :label="$t('account.password.form.password_current.label')">
                                <input-component
                                    v-model="password.current"
                                    autocomplete="current-password"
                                    type="password"
                                    :placeholder="$t('account.password.form.password_current.placeholder')"
                                    @input="reset"
                                    @mousedown.stop
                                />
                            </input-group>
                        </validation-provider>

                        <validation-provider name="password_new" rules="required|min:8|different:password" v-slot="{ errors, reset }" slim>
                            <input-group :errors="errors" :label="$t('validation.names.password_new')">
                                <input-component
                                    v-model="password.new"
                                    autocomplete="new-password"
                                    type="password"
                                    :placeholder="$t('account.password.form.password_new')"
                                    @input="reset"
                                    @mousedown.stop
                                />
                            </input-group>
                        </validation-provider>

                        <validation-provider name="password_new_confirmation" rules="required|confirmed:password_new" v-slot="{ errors, reset }" slim>
                            <input-group :errors="errors" :label="$t('validation.names.password_new_confirmation')">
                                <input-component
                                    v-model="password.confirmation"
                                    autocomplete="new-password"
                                    type="password"
                                    :placeholder="$t('account.password.form.password_new_confirmation')"
                                    @input="reset"
                                    @mousedown.stop
                                />
                            </input-group>
                        </validation-provider>
                    </div>

                    <input :value="user.email" autocomplete="username" hidden />

                    <text-button :loading="password.loading" full>{{ $t('account.password.form.button') }}</text-button>
                </form>
            </validation-observer>
        </card>

        <template #modal>
            <recovery-modal v-if="modal.opened" :codes="modal.codes" @close="closeModal" />
        </template>
    </app-layout>
</template>

<script>
    import { mapState } from 'vuex';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { changePassword, recoveryCodes } from '@/services/skinsPaymentApi';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import AppLayout from '@/pages/layout/AppLayout';
    import Card from '@/components/Card';
    import ClipIcon from '@/components/icons/ClipIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import KeyIcon from '@/components/icons/KeyIcon';
    import Modal from '@/components/Modal';
    import RecoveryModal from '@/components/tfa/RecoveryModal';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'Account',
        components: {
            AppLayout,
            Card,
            ClipIcon,
            InputComponent,
            InputGroup,
            KeyIcon,
            Modal,
            RecoveryModal,
            RetryArrowIcon,
            TextButton,
            ValidationObserver,
            ValidationProvider,
        },
        mixins: [responseProcessingMixin],
        computed: {
            ...mapState('user', ['user']),
        },
        methods: {
            closeModal() {
                this.modal.codes = [];
                this.modal.opened = false;
            },
            getRecoveryCodes() {
                this.tfa.loading = true;

                recoveryCodes(this.tfa.password)
                    .then(({ data }) => {
                        this.tfa.password = null;
                        this.$refs.tfaObserver.reset();

                        this.modal.opened = true;
                        this.modal.codes = data;
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.tfaObserver))
                    .finally(() => (this.tfa.loading = false));
            },
            sendPasswordChange() {
                this.password.loading = true;

                return changePassword(this.password.current, this.password.new, this.password.confirmation)
                    .then(() => {
                        this.password.current = null;
                        this.password.new = null;
                        this.password.confirmation = null;
                        this.$refs.passwordObserver.reset();
                        this.$toast.success(this.$t('account.password.success'));
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.passwordObserver))
                    .finally(() => (this.password.loading = false));
            },
        },
        data() {
            return {
                password: {
                    loading: false,
                    current: null,
                    new: null,
                    confirmation: null,
                },
                tfa: {
                    loading: false,
                    password: null,
                },
                modal: {
                    opened: false,
                    codes: [],
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .account__text {
        p,
        span {
            color: #8b99bb;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            font-weight: 325;
            line-height: 1.4;
        }

        p {
            margin-bottom: 1.5rem;

            &:last-of-type span {
                color: #5dd880;
            }
        }
    }

    .account__password form::v-deep .input-group {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    @media screen and (min-width: 64em) {
        .account__tfa form {
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: 2fr 1fr;

            > * {
                margin: 0;
            }
        }

        .account__password form {
            > div:first-child {
                display: grid;
                grid-gap: 1.5rem;
                grid-template-columns: 1fr 1fr 1fr;
            }

            ::v-deep .input-group {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .account__tfa form {
            grid-gap: 2.5rem;
            grid-template-columns: 3fr 1fr;
        }

        .account__text {
            p,
            span {
                font-size: 1.5rem;
            }

            p {
                margin-bottom: 2.5rem;
            }
        }

        .account__password form {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 2.5rem;

            > div:first-child {
                grid-gap: 2.5rem;
            }

            > p {
                margin-bottom: 2.5rem;
                font-size: 1.2rem;
            }
        }
    }
</style>
