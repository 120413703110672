<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32.9834 7.0332C28.0501 2.11654 20.0501 2.11654 15.1501 7.0332C11.7001 10.4499 10.6667 15.3665 12.0001 19.6999L4.16675 27.5332C3.61675 28.0999 3.23341 29.2165 3.35008 30.0165L3.85008 33.6499C4.03341 34.8499 5.15008 35.9832 6.35008 36.1499L9.98341 36.6499C10.7834 36.7665 11.9001 36.3999 12.4667 35.8165L13.8334 34.4499C14.1667 34.1332 14.1667 33.5999 13.8334 33.2665L10.6001 30.0332C10.1167 29.5499 10.1167 28.7499 10.6001 28.2665C11.0834 27.7832 11.8834 27.7832 12.3667 28.2665L15.6167 31.5165C15.9334 31.8332 16.4667 31.8332 16.7834 31.5165L20.3167 27.9999C24.6334 29.3499 29.5501 28.2999 32.9834 24.8832C37.9001 19.9665 37.9001 11.9499 32.9834 7.0332ZM24.1667 19.9999C21.8667 19.9999 20.0001 18.1332 20.0001 15.8332C20.0001 13.5332 21.8667 11.6665 24.1667 11.6665C26.4667 11.6665 28.3334 13.5332 28.3334 15.8332C28.3334 18.1332 26.4667 19.9999 24.1667 19.9999Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'KeyIcon',
    };
</script>
